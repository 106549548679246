.vd-custom-heading
{
    display: block;

    width: 100%;
    &.vd-custom-heading-align-left
    {
        text-align: left;
    }
    &.vd-custom-heading-align-center
    {
        text-align: center;
    }
    &.vd-custom-heading-align-right
    {
        text-align: right;
    }
    > a
    {
        cursor: pointer;
        text-decoration: none;
        &:hover
        {
            text-decoration: none;
        }
    }
}
